import api from "./instance";

/**
 * create quiz
 */
const create = (quiz) =>
  api.post(`/admin/quizzes`, { quiz }).then((response) => response.data);

const getQuiz = (lecture_id) =>
  api
    .get(`/admin/quizzes/render_quiz`, { params: { lecture_id } })
    .then((response) => response.data);

/**
 * update quiz
 */
const update = (quiz) =>
  api
    .put(`/admin/quizzes/${quiz.id}`, { quiz })
    .then((response) => response.data);

/**
 * create question
 */
const createQuestion = (quiz, question) =>
  api
    .post(`/admin/quizzes/${quiz.id}/quiz_questions`, question)
    .then((response) => response.data);

/**
 * update question
 */
const updateQuestion = (quiz, question) =>
  api
    .put(`/admin/quizzes/${quiz.id}/quiz_questions/${question.id}`, question)
    .then((response) => response.data);

/**
 * remove question
 */
const destroyQuestion = (quiz, question) =>
  api
    .delete(`/admin/quizzes/${quiz.id}/quiz_questions/${question.id}`)
    .then((response) => response.data);

/**
 * create answer
 */
const createAnswer = (quiz, question, answer) =>
  api
    .post(
      `/admin/quizzes/${quiz.id}/quiz_questions/${question.id}/quiz_answers`,
      answer
    )
    .then((response) => response.data);

/**
 * update answer
 */
const updateAnswer = (quiz, question, answer) =>
  api
    .put(
      `/admin/quizzes/${quiz.id}/quiz_questions/${question.id}/quiz_answers/${answer.id}`,
      answer
    )
    .then((response) => response.data);

/**
 * remove answer
 */
const destroyAnswer = (quiz, question, answer) =>
  api
    .delete(
      `/admin/quizzes/${quiz.id}/quiz_questions/${question.id}/quiz_answers/${answer.id}`
    )
    .then((response) => response.data);

/**
 remove quiz
 */
const destroyQuiz = (quiz) =>
  api.delete(`/admin/quizzes/${quiz.id}`).then((response) => response.data);

const createAiQuiz = (params) =>
  api
    .post(`/admin/ai_quizzes/generate`, params)
    .then((response) => response.data);

const confirmAiQuix = (params) =>
  api
    .get(`/admin/ai_quizzes/confirm`, { params })
    .then((response) => response.data);

const checkJobStatus = (params) =>
  api
    .get(`/admin/ai_quizzes/check_job_status`, { params })
    .then((response) => response.data);

export default {
  create,
  update,
  createQuestion,
  updateQuestion,
  destroyQuestion,
  createAnswer,
  updateAnswer,
  destroyAnswer,
  getQuiz,
  destroyQuiz,
  createAiQuiz,
  confirmAiQuix,
  checkJobStatus,
};
