<template>
  
  <div>
    <div class="flex justify-between mb-4">
      <div class="flex">
         <BackButton href="javascript:history.go(-1)" :cssClass="'mr-4'" />
         <h2 class="text-xl">{{t('pages.user.info')}}</h2>
      </div>
    </div>
    <div class="Box Box--Clear mb-4">
      <div class="sm:grid md:grid-cols-2 lg:grid-cols-3  gap-3 mb-4">
       
        <div>
          <p><strong>Email:</strong></p>
          <p v-if="!isEditMode">{{ user.email }}</p>
          <input v-else v-model="user.email" type="text" class="input-field" />
        </div>
        <div v-for="userField in user.userFields" :key="userField.name">
          <p><strong>{{ t(`user_field.${userField.name}`) }}:</strong></p>
          <p v-if="!isEditMode">{{ userField.value }}</p>
          <input v-else v-model="userField.value" type="text" class="input-field" />
        </div>
        <div v-if="user.fiscal_code">
          <p><strong>{{t('user_field.fiscal_code')}}:</strong></p>
          <p v-if="!isEditMode">{{ user.fiscal_code }}</p>
          <input v-else v-model="user.fiscal_code" type="text" class="input-field" />
        </div>
        <div v-for="userSetting in user.userSettings" :key="userSetting.name">
          <p><strong>{{ t(`user_setting.${userSetting.name}`) }}:</strong></p>
          <p v-if="!isEditMode">{{ userSetting.value || 'Default' }}</p>
          <input v-else v-model="userSetting.value" type="text" class="input-field" />
        </div>
        <div v-if="user.invitationCreatedAt">
          <p><strong>{{ t('pages.users.invitation_status') }}:</strong></p>
          <p class="">{{ user.invitationAccepted ? t('pages.users.accepted') : t('pages.users.pending') }}</p>
        </div>
        <div v-if="user.invitationCreatedAt && !user.invitationAccepted">
          <p><strong>{{ t('pages.users.invitation_link') }}:</strong></p>
          <p class="break-all">{{ user.invitation_url }}</p>
        </div>
        
        <div>
          <p><strong>{{ t('pages.users.total_duration') }}:</strong></p>
          <p class="">{{ userDuration() }}</p>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <button v-if="isEditMode" @click="saveChanges" class="Btn Btn--Save mt-4">{{t('actions.save')}}</button>
        <button @click="toggleEditMode" :class="['Btn', {'Btn--Delete': isEditMode, 'Btn--Save': !isEditMode}]">
          {{ isEditMode ? t('actions.close') : t('actions.edit') }}
        </button>
        <div v-if="user.disabled" class="flex gap-2 items-center">
          <p class="bg-danger text-white rounded p-2">{{`${ t('global.user.s')} ${ t('pages.users.disabled')}` }}</p>
          <button  @click="enableUser()"class="Btn Btn--Edit">{{t('pages.users.enable_user')}}</button>
        </div>
        <form class="" v-else-if="user.invitationAccepted" method="post" :action="`/impersonate/${user.id}`">
          <input class="Btn Btn--Text text-danger" type="submit" :value="`${t('pages.impersonation.impersonate')} ${user.firstName}  ${user.lastName}`">
        </form>
      </div>
      
    </div>


    <div class="Box Box--Clear mb-4">
       <h2 class="mb-4">{{t('global.stats')}}</h2>
       <button  class="Btn Btn--Save" :disabled="exportingStats" @click="exportStats">{{t('actions.export_stats')}}</button>
    </div>

    <p v-if="exportingStats" class="text-center mb-2">
      Export in corso. Riceverai una mail tra qualche minuto con il file excel.
    </p>
    



    <div class="Box Box--Clear mb-4" v-if="user.userFieldsCoach && user.userFieldsCoach.length > 0">
      <h2 class="mb-4 text-xl">{{t('pages.user.coaching_info')}}</h2>
      <span v-for="userField in user.userFieldsCoach" :key="userField.id">
        <p><strong>{{ userField.name }}:</strong></p>
        <p class="mb-4">{{ userField.value }}</p>
      </span>
    </div>

    <div v-if="coachingEnabled" class="Box Box--Clear mb-4">
      <h4 class="mb-4">{{t('pages.user.coaching_sessions')}}</h4>
      <newCoachingSession :user="user" :coaches="coaches" />
      <div v-if="futureSessions.length > 0" class="mb-2">
        <h4>{{t('pages.user.future_sessions')}}</h4>
          <ul v-for="session in futureSessions" :key="session.id" class="List--Lined">
            <li class="flex justify-between items-cente">
              <span>{{session.lecture.name}}</span>
              {{ session.lecture.lecture_info['start_time'] | moment("D/M/YY k:mm")}}
              <!-- {{ session.completed}} -->
            </li>
          </ul>
      </div>
       <div v-if="pastSessions.length > 0" class="mb-2">
        <h4>{{t('pages.user.past_sessions')}}</h4>
        <ul v-for="session in pastSessions" :key="session.id" class="List--Lined">
          <li class="flex justify-between items-center">
            <span>{{session.lecture.name}}</span>
            {{ session.lecture.lecture_info['start_time'] | moment("D/M/YY k:mm")}}
          </li>
        </ul>
       </div>
    </div>

     <div class="Box Box--Clear mb-4" v-if="enrollPaths.length > 0">
      <h4 class="mb-4">{{t('global.paths.p')}}: ({{enrollPaths.length}})</h4>
      <ul class="List--Lined">
        <li v-for="enroll in pageOfItemsPaths" :key="enroll.id" class="flex flex-wrap justify-between items-center">
          <a :href="`/admin/courses/${enroll.course.id}/stats`">{{ enroll.course.name }}</a> 
          <div class="flex items-center">
            <progressBar :progress="enroll.percentageCompleted/100"  />
            <span class="ml-4 w-12 font-bold">{{ Math.round(enroll.percentageCompleted) }}%</span>
          </div>   
        </li>
      </ul>
      <div class="text-center mt-4">
          <jw-pagination :items="enrollPaths" @changePage="onChangePagePaths" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
    </div>

    <div class="Box Box--Clear mb-4">
      <h4 class="mb-4">{{t('global.courses.p')}}: ({{enrollCourses.length}})</h4>
      <ul class="List--Lined">
        <li v-for="enroll in pageOfItemsCourses" :key="enroll.id" class="flex flex-wrap justify-between items-center">
          <a :href="`/admin/courses/${enroll.course.id}/stats`">{{ enroll.course.name }}</a> 
          <div class="flex items-center">
            <span v-if="enroll.progress_status"><strong>{{ t(`pages.course.${enroll.progress_status}`) }}</strong></span>
            <template v-else>
              <progressBar :progress="enroll.percentageCompleted/100"  />
              <span class="ml-4 w-12 font-bold">{{ Math.round(enroll.percentageCompleted) }}%</span>
            </template>
          
          </div>   
        </li>
      </ul>
       <div class="text-center mt-4">
          <jw-pagination :items="enrollCourses" @changePage="onChangePageCourses" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
    </div>

    <div class="Form--Boxed mb-8" v-if="currentUserAdminRole != 'inspector'">
      <h4 class="mb-4 flex items-center justify-between">{{t('pages.user.invite_courses')}}
      <div>
        <button @click="openSubscriptionModal" class="Btn Btn--Success">{{t('actions.invite_all_courses')}}</button>
        <button @click="openRemoveSubscriptionModal" class="Btn Btn--Delete">{{t('actions.remove_from_all_courses')}}</button>
      </div>
      </h4>

      <div class="flex flex-1 mb-4">
          <input  v-model="search" type="text" :placeholder="t(`pages.courses.search_undefined`)"  class="flex-1 mr-2" v-on:keyup.enter="getCoursesFromSearch"   />
          <button @click="getCoursesFromSearch" class="Btn Btn--Save">{{t('actions.search')}}</button>
        </div>
      <div v-if="loading" class="LoadingBox mt-4 text-center">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
    <div v-else-if="error != ''" class="Box Box--Clear">
      <p class="text-danger">{{error}}</p>
    </div>
    <div v-else>
      <div v-if="allCourses.length > 0">
        <ul class="List--Lined List--Lined--Thin">
          <li v-for="course in pageOfItems" :key="course.id" class="flex items-center justify-between">
            <span>{{course.name}}</span>
            <button class="flex items-center" v-if="!course.enrolled"  @click="addCourse(course)">
              <span class="mr-2 text-warning cursor-pointer">{{t('global.not_enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--UnChecked "></span>
            </button>
            <button v-else class="flex items-center" @click="removeCourse(course)">
              <span class="mr-2 text-success cursor-pointer">{{t('global.enrolled_course')}}</span>
              <span class="Btn Btn--CheckStyle  Btn--CheckStyle--Checked">
                <svg height="16" width="16" class="fill-current"> 
                  <use xlink:href="#icon-checkmark"></use>
                </svg>
              </span>
            </button>
          </li>
        </ul>
         <div class="text-right mt-2">
          <button :disabled="saveDisabled" class="Btn Btn--Save" @click="openModal()">
            {{t('actions.save_changes')}} {{saveDisabled ? '' : `(${addedCourses.length+removedCourses.length} corsi)`}}
          </button>
        </div>
        <div class="text-center mt-2">
          <jw-pagination  :items="allCourses" @changePage="onChangePage" :labels="customLabels" class="PaginationList"></jw-pagination>
        </div>
      </div>
      <div v-else class="mt-4 text-center">
        <p>{{t('pages.courses.no_courses')}}</p>
       </div>
    </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="Box Box--Clear">
        <h4 class="mb-2">Reset password</h4>
        <button @click="resetPassword()" class="Btn Btn--Save mb-4">{{t('pages.users.generate_new_password')}}</button>
        <div v-if="newPw" class="">
          <p class="font-bold mb-2">{{t('pages.users.new_reset_link')}}: </p>
          <p class="mb-2">{{newPw}}</p>
          <button class="js-clip flex items-center gap-2" :data-clipboard-text="newPw">
            <strong>{{t('actions.copy_to_clipboard')}}</strong>
            <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-clipboard"></use>
              </svg>
          </button>
        </div>
      </div>
      <div v-if="currentUserAdminRole != 'inspector'" class="Box Box--Clear">
        <h4 class="mb-2">Danger zone</h4>
        <button v-if="!user.disabled" @click="disableUser()"class="Btn Btn--Edit">{{t('pages.users.disable_user')}}</button>
        <button @click="removeUser()" class="Btn Btn--Delete">{{t('pages.users.destroy_user')}}</button>
      </div>
    </div>

    <modal name="confirm-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('pages.user.editing')}}:</h2>
      </div>
      <div class="v--modal--body body--users p-4">
          <div v-if=" addedCourses.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.user.courses_added')}}: ({{ addedCourses.length }})</h4>
            <ul class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="course in addedCourses" :key="course.id">
              {{ course.name}}
              </li>
            </ul>
          </div>
          <div v-if=" removedCourses.length > 0" class="mb-4">
            <h4 class="mb-2">{{t('pages.user.courses_removed')}}: ({{ removedCourses.length }})</h4>
            <ul v-if=" removedCourses.length > 0" class="v--modal--list List--Lined List--Lined--Thin ColoredScrollBar">
              <li v-for="course in removedCourses" :key="course.id">
                {{course.name }}
              </li>
            </ul>
          </div>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveCourses">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="$modal.hide('confirm-modal')">{{t('actions.undo')}}</button>
      </div>
    </modal>

    <modal name="subscription-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('actions.confirm?')}}</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <p>{{t('pages.users.add_subscription.text1', [user.firstName, user.lastName])}}</p>
        <p>{{t('pages.users.add_subscription.text2')}}</p>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveCourses">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="reload">{{t('actions.undo')}}</button>
      </div>
    </modal>

    <modal name="remove-subscription-modal" height="auto">
      <div class="v--modal--header p-4">
        <h2 class="text-xl">{{t('actions.confirm?')}}</h2>
      </div>
      <div class="v--modal--body body--users p-4">
        <p>{{t('pages.users.remove_subscription.text1', [user.firstName, user.lastName])}}</p>
        <p>{{t('pages.users.remove_subscription.text2')}}</p>
      </div>
      <div class="v--modal--actions sm:flex sm:justify-end p-4">
        <button class="Btn Btn--Save" @click="saveCourses">{{t('actions.save')}}</button>
        <button class="Btn Btn--Edit ml-2" @click="reload">{{t('actions.undo')}}</button>
      </div>
    </modal>

  </div>


  


</template>

<script>
import api from '../../../api'
import BackButton from '../../commons/backButton'
import progressBar from '../../commons/progressBar'
import newCoachingSession from '../../commons/newCoachingSession'
import { mapState, mapActions } from 'vuex'
import { map, chunk, defaultTo } from "lodash";
import * as XLSX from 'xlsx-js-style';
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { global as EventBus } from '../../../js/utils/eventBus'; 



export default {
  components: {
    BackButton,
    progressBar,
    newCoachingSession,
    DatePicker
  },
  props: {
    user: Object,
    enrolls: Array,
    pastSessions: Array,
    futureSessions: Array,
    coachingEnabled: Boolean,
    coaches: Array,
    currentUserAdminRole: String
  },
  data() {
    return {
      page: 1,
      courses: [],
      loading: false,
      search: '',
      error: '',
      light: true,
      addedCourses: [],
      removedCourses: [],
      pageOfItems: [],
      pageOfItemsCourses: [],
      pageOfItemsPaths: [],
      customLabels: {
        first: '<<',
        last: '>>',
        previous: '<',
        next: '>'
      },
      enrolledCourses: [],
      enrolledPaths: [],
      allSelected: false,
      exportingStats: false,
      allLectureActivities: [],
      allQuizActivities: [],
      dateRange: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      subscription: false,
      isEditMode: false,
      newPw: '',
    }
  },
  computed: {

    projectInfo() {
      return [{
        name: `${this.user.firstName} ${this.user.lastName}`,
        email: `${this.user.email}`,
        duration: this.userDuration()
      }]
    },
    enrollCourses(){
      return this.enrolls.filter(enroll => !enroll.course.path)
    },
    enrollPaths(){
      return this.enrolls.filter(enroll => enroll.course.path)
    },
    ...mapState({ 
      allCourses: state => state.course.allCourses.sort((a, b) => (a.name > b.name) ? 1 : -1)
    }),
    addedCourseIds() {
      return this.addedCourses.map(el => el.id)
    },
    removedCourseIds() {
      return this.removedCourses.map(el => el.id)
    },
    saveDisabled() {
      return this.addedCourses.length === 0 && this.removedCourses.length === 0
    },
  },
  mounted() {
   this.getCourses()
   
  },
  methods: {
     ...mapActions({
      getAllCourses: 'getAllCourses'
    }),

    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
      
    },
    saveChanges() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });

      // Create an array of promises for updating user fields
      const updateFieldPromises = this.user.userFields.map(field => api.UserField.update(field));
      const updateSettingPromises = this.user.userSettings.map(setting => api.UserSetting.update(setting));

      // Wait for all user fields to be updated
      Promise.all(updateFieldPromises, updateSettingPromises)
        .then(() => {
          // All user fields have been updated, now update the user
          return api.User.update(this.user);
        })
        .then(res => {
          this.isEditMode = false;
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target });
        })
        .catch(err => {
          console.log('figa');
          console.log(err);
          this.$toastr.e(this.t('errors.messages.try_again'));
          EventBus.emit('loader:local:end', { elem: target });
        });
    },

    exportStats(){
      api.User.exportUserStats(this.user)
      .then( () => {
        this.exportingStats = true
      })
    },

    
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    onChangePageCourses(pageOfItems) {
      // update page of items
      this.pageOfItemsCourses = pageOfItems;
    },
    onChangePagePaths(pageOfItems) {
      // update page of items
      this.pageOfItemsPaths = pageOfItems;
    },
    getCourses() {
      this.loading = true

      api.Course.getCourses({
        page: this.page, 
        search: this.search, 
        type: this.type, 
        light: this.light, 
        user_id: this.user.id,
        public: true
      }).then(res => {

        this.getAllCourses(res.courses)
        this.loading = false
      }).catch( err => {
        this.error = err.message
        this.loading = false
      })
    },
   
   getCoursesFromSearch(){
      this.getCourses()
    },


    userDuration() {
      let totalSeconds = this.user.user_duration ? this.user.user_duration : 0
      const hours = Math.floor(totalSeconds / 3600);
      totalSeconds %= 3600;
      const minutes = Math.floor(totalSeconds / 60);
      const seconds = totalSeconds % 60;
      return `${hours}h: ${minutes}m`
    },


    removeUser(){
      if(confirm(  `${this.t('pages.confirm.user.delete')}\n${this.t('pages.confirm.user.are_you_sure')}`)){
        api.User.destroy(this.user)
        .then(res => {
          location.href = `/admin/users`
        })
      }
    },

    disableUser(){
      if(confirm( `${this.t('pages.confirm.user.disable')}\n${this.t('pages.confirm.user.are_you_sure')}` )){
        api.User.update({id: this.user.id, disabled: true})
        .then(res => {
          console.log(res)
          location.reload()
        })
      }
    },

    enableUser(){
      if(confirm( `${this.t('pages.confirm.user.enable')}\n${this.t('pages.confirm.user.are_you_sure')}` )){
        api.User.update({id: this.user.id, disabled: false})
        .then(res => {
          console.log(res)
          location.reload()
        })
      }
    },

    addCourse(course) {
      course.enrolled = true
      
      // user was enrolled but added to removes user. doesn't have to be added
      if ( this.removedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.removedCourses = this.removedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.addedCourses.push(course)
      }
    },
    removeCourse(course) {
      course.enrolled = false
      // user was not enrolled but added to added user. doesn't have to be removed
      if ( this.addedCourses.filter(item => item.id === course.id ).length > 0  ) {
        this.addedCourses = this.addedCourses.filter(function( obj ) {
          return obj.id !== course.id;
        });
      } else {
        this.removedCourses.push(course)
      }
      
    },
    addAllCourses() {
      //TODO
      //aprire modale
      //confermando, l'utente avrà accesso a tutti i corsi
      this.allCourses.forEach(el => {
        this.addCourse(el)
        this.allSelected = true
        this.subscription = true
      })
      //user.subscription = true
    },
    removeAllCourses() {
      this.allCourses.forEach(el => {
        this.removeCourse(el)
      })
      this.subscription = false
    },
    reload() {
      location.reload()
    },
    openModal() {
      this.$modal.show('confirm-modal');
    },

    openSubscriptionModal() {
      this.$modal.show('subscription-modal');
      this.addAllCourses()
    },

    openRemoveSubscriptionModal() {
      this.$modal.show('remove-subscription-modal');
      this.removeAllCourses()
    },

    generateSheet() {

      const  wb = XLSX.utils.book_new()

      const sheets = [
        {
          heading: [ [
            { v: "Nome", t: "s", s:{ font: {  bold: true } } },
            { v: "Email", t: "s", s:{ font: {  bold: true } } },
            { v: "Ore fruite", t: "s", s:{ font: {  bold: true } } }
          ]],
          ws: XLSX.utils.json_to_sheet([]),
          arr: this.projectInfo,
          name:  "Info generali"
        },
        {
          heading: [ [
            { v: "Ora di entrata", t: "s", s: { font: { bold: true } } },
            { v: "Ora di uscita", t: "s", s:{ font: {  bold: true } } },
            { v: "Durata", t: "s", s: { font: {  bold: true } } },
            { v: "Titolo lezione", t: "s", s:{ font: {  bold: true } } },
            { v: "Titolo Corso", t: "s", s:{ font: {  bold: true } } },
          ]],
          ws: XLSX.utils.json_to_sheet([]),
          arr: this.lectureStatsList(this.allLectureActivities),
          name: "Sessioni"
        },
        {
          heading: [ [
            { v: "Ora di entrata", t: "s", s: { font: { bold: true } } },
            { v: "Ora di uscita", t: "s", s:{ font: {  bold: true } } },
            { v: "Risultato", t: "s", s: { font: {  bold: true } } },
            { v: "Titolo lezione", t: "s", s:{ font: {  bold: true } } },
            { v: "Titolo Corso", t: "s", s:{ font: {  bold: true } } },
          ]],
          ws: XLSX.utils.json_to_sheet([]),
          arr: this.quizList(this.allQuizActivities),
          name: "Quiz"
        }
      ]

      sheets.forEach(el => {
        XLSX.utils.sheet_add_aoa(el.ws, el.heading);
        XLSX.utils.sheet_add_json(el.ws, el.arr, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, el.ws, el.name)
      })

      XLSX.writeFile(wb, `Report_${this.user.firstName}_${this.user.lastName}.xlsx`)
    },

  
    lectureStatsList(lectureActivities) { 
      return lectureActivities.map(el => {
          return {
            from: el.start_datetime ? moment(el.start_datetime).format('DD/MM/YYYY - HH:mm:ss') : 'No date',
            to:  el.end_datetime ? moment(el.end_datetime).format('DD/MM/YYYY - HH:mm:ss') : 'No date',
            duration: new Date(defaultTo( el.duration, 0) * 1000).toISOString().substr(11, 8),
            title: el.title,
            course_name: el.course_name
          }
      })
    },


      
    quizList(quizActivities) {
      //Come visualizzare i quiz? 
      return map(chunk(quizActivities, 2), (activityPair) => {
        console.log(activityPair)
        const enterLecture = activityPair.find((obj) => (obj.activity_type === "started-quiz-attempt" ))
        const exitLecture = activityPair.find((obj) => (obj.activity_type !== "started-quiz-attempt"   ))
          return {
                from: moment(enterLecture?.start_datetime).format('DD/MM/YYYY - HH:mm:ss'),
                to:  moment(exitLecture?.start_datetime).format('DD/MM/YYYY - HH:mm:ss'),
                result: exitLecture?.activity_type,
                title: enterLecture?.title,
                course_name: enterLecture?.course_name
            };
        });
    },



    async saveCourses() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      const createEnrolls = api.Enroll.createMultipleEnrolls({
        user: this.user, 
        course_ids: this.addedCourseIds,
        subscription: this.subscription
      })
      const destroyEnrolls = api.Enroll.destroyEnrolls([this.user], this.removedCourseIds);
      Promise.all([await createEnrolls, await destroyEnrolls])
        .then( res => {
          this.addedCourses = [];
          this.removedUsers = [];
          this.$modal.hide('confirm-modal');
          this.$toastr.s(this.t('actions.saved'));
          EventBus.emit('loader:local:end', { elem: target });
        })
        .catch(err => {
          this.$toastr.e(this.t('errors.messages.try_again'));
          EventBus.emit('loader:local:end', { elem: target });
        })
    },

    resetPassword() {
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target });
      api.User.resetPassword(this.user)
      .then(res => {
        this.newPw = res.reset_link
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target });
        
      })
      .catch(err => {
        this.$toastr.e(this.t('errors.messages.try_again'));
        EventBus.emit('loader:local:end', { elem: target });
      });
    },

   
  }
}
</script>


<style scoped>
    @media only screen and (max-width: 768px) {
      .mx-datepicker-range {
        width: 100%;
      }
    }

    .js-clip {
     position: static;
    }

    input[type="submit"] {
      background: none;
    }

</style>