<template>

  <div class="">
    <button class="font-bold ai-button flex items-center gap-2" @click.prevent="openGenerateQuiz =  !openGenerateQuiz">
      {{t('pages.quiz.generate_with_ai')}}
      <img src="../../../images/icons/magic-wand.png" width="24"/>
    </button>
    <div v-if="openGenerateQuiz" class="mt-4">
      <div v-if="!generatedQuiz && !loading">
        <div class="grid grid-cols-2 gap-4">
          <div class="field">
            <label for="">{{t('pages.quiz.questions_number')}}</label>
            <input :class="[aiInputError ? 'error' : '']" type="number" v-model="aiQuestions">
          </div>
          <div class="field">
            <label for="">{{t('pages.quiz.answers_number')}}</label>
            <input :class="[aiInputError ? 'error' : '']" type="number" v-model="aiAnswers">
          </div>
        </div>
        <p class="font-bold mb-2">{{t('pages.quiz.write_prompt')}}</p>
        <textarea 
          v-model="prompt" 
          name="prompt" 
          id="prompt" 
          rows="5"
          :class="[aiInputError ? 'error' : '', 'mb-2']"
          :placeholder="t('pages.quiz.write_prompt_placeholder')"
          >
        </textarea>
        <div>
          <div class="file-upload mt-2 mb-2">
            <input type="file" ref="fileInput" @change="handleFileUpload" multiple style="display: none;">
            <button class="flex items-center gap-2" @click="$refs.fileInput.click()" :disabled="files.length >= 3">
              <svg height="14" width="14" class="fill-current"> 
                <use xlink:href="#icon-attachment"></use>
              </svg>
              <strong>{{ t('pages.comments.attach') }}</strong>
            </button>
            <p class="mt-2" v-if="files.length > 0">{{ files.length }} {{ t('pages.comments.files_attached') }}</p>
          </div>
          <div v-if="files.length > 0" class="file-preview mt-2 mb-2">
            <div v-for="(file, index) in files" :key="index" class="file-item">
              <span>{{ file.name || file.filename }}</span>
              <button class="Btn Btn--Icon Btn--Delete ml-2" @click="removeFile(index)">
                <svg height="14" width="14" class="fill-current"> 
                    <use xlink:href="#icon-close"></use>
                  </svg>
              </button>
            </div>
          </div>
        </div>
        <button  class="Btn Btn--Save" @click="callAI">{{t('actions.go')}}</button>
      </div>
      <p v-else-if="generatedQuiz && !loading" class="mt-6 mb-2 font-bold text-lg" @click="confirmQuiz">{{ t('pages.quiz.ai_generation_ready') }}</p>
      <div v-if="loading" class="mt-4">
        <div class="flex items-center justify-center image-placeholder animated-background mb-3">
          <p  class="px-10 ai-loading text-lg font-bold">{{t('pages.quiz.ai_generation_loading')}}</p>
        </div>
      </div>
      <div v-if="generatedQuiz" class="mt-4">
          <vue-editor 
            :id="`prompt`"
            v-model="generatedQuiz" 
            :editorToolbar="customToolbar"
            class="mb-4"
          >
        </vue-editor>
        <button class="Btn Btn--Save" @click="confirmQuiz">{{t('actions.confirm')}}</button>
        <button class="Btn Btn--Delete" @click="generatedQuiz = ''">{{t('actions.delete')}}</button>
      </div>
    </div>

    <modal :name="`confirm-modal`" height="auto">
      <div class="Hero v--modal--body body--users p-4 pt-10 text-center">
        <img src="../../../images/party1.png" class="Hero__Img1"/>
        <img src="../../../images/party2.png" class="Hero__Img2"/>
        <p class="font-bold text-2xl mb-4">{{t('pages.confirm.congrats')}}</p>
        <p>{{t('pages.quiz.quiz_generated')}}</p>
        <p class="mb-4">{{ t('pages.quiz.reload_page') }}</p>
        <button class="Btn Btn--Save" @click="reload">{{t('actions.reload')}}</button>
      </div>
    </modal>

  </div>

</template>

<script>

import api from '../../../api'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus'; 



export default {
  props: {
    quiz: Object
  },
  data() {
    return {
      files: [],
      aiInputError: false,
      loading: false,
      openGenerateQuiz: false,
      editPrompt: false,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      prompt: "",
      aiQuestions: null,
      aiAnswers: null,
      generatedQuiz: "",
      newQuestion: {
        body: ""
      },
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]]
    }
  },
  components: {
    VueEditor
  },

  watch: {
    prompt(){
      this.aiInputError = false
    }
  },
  methods: {

    
    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      const availableSlots = 3 - this.files.length;
      const filesToAdd = newFiles.slice(0, availableSlots);
      this.files = [...this.files, ...filesToAdd];
      
      if (newFiles.length > availableSlots) {
        this.$toastr.w(`Only ${availableSlots} file(s) added. Max limit reached.`);
      }
    },

    removeFile(index) {
      const removedFile = this.files[index];
      if (removedFile.id) {
        // If the file has an ID, it's from the backend, so we track it for deletion
        this.deletedFileIds.push(removedFile.id);
      }
      this.files.splice(index, 1);
    },


 

    callAI(){
      if (!this.prompt){
        this.aiInputError = true
        return
      }
      this.loading = true

      //test wirth file upload
      const formData = new FormData();
      formData.append('prompt', this.prompt);
      formData.append('questions', this.aiQuestions);
      formData.append('answers', this.aiAnswers);
      formData.append('quiz_id', this.quiz.id);
      this.files.forEach((file, index) => {
        formData.append(`attachments[]`, file);
      });
      api.Quiz.createAiQuiz(
        formData
      )
      /*
      api.Quiz.createAiQuiz({
        prompt: this.prompt,
        questions: this.aiQuestions,
        answers: this.aiAnswers
      })
        */
  
      .then(res => {
          console.log(res)
          //this.aiLoading = false
          //polly ajax
          const intervalId = setInterval(() => {
            api.Quiz.checkJobStatus({
              job_id: res.job_id
            })
            .then(resp => {
              if (resp.status == 'error') {
                //this.aiError = resp.error_code
                this.$toastr.e('Error');
                this.loading = false;
                clearInterval(intervalId); // Stop polling
              } else if (resp.text) {
                this.generatedQuiz = resp.text
                this.loading = false
                clearInterval(intervalId); // Stop polling
              }
            })
            .catch(err => {
              this.$toastr.e(err);
              clearInterval(intervalId); // Stop polling if an error occurs
              this.loading = false;
            });
          }, 1500);
        } )
      .catch(e =>{
        this.loading = false
        this.$toastr.e(this.t('actions.error'));
      })
    },

    confirmQuiz(){
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Quiz.confirmAiQuix({
        quiz_id: this.quiz.id,
        quiz_text: this.generatedQuiz
      })
      .then( res => {
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        this.$modal.show(`confirm-modal`);
      }).catch( err => {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      } )
    },

    reload(){
      location.reload()
    }
  }
}
</script>

<style scoped>
  .image-placeholder {
    height: 400px;
  }
</style>
