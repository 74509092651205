<template>
  <div>
    <div v-if="error" class="Box Box--Clear mt-4 mb-4">
      <p class="font-bold text-danger">{{ error }}</p>
    </div>
    <div v-else-if="!courseContent && !loading">
      <div class="Form--Boxed mb-4">
        <div class="flex items-center justify-between">
          <h1 class="text-2xl">{{t('pages.course.course_generation.generate_with_ai_long')}}</h1>
          <button class="Btn Btn--Delete Btn--Icon" @click="closeBox">
            <svg height="16" width="16" class="fill-current">
              <use xlink:href="#icon-close"></use>
            </svg>
          </button>
        </div>
        <p class="mb-4 text-grayScale-middle">{{t('pages.course.course_generation.generate_with_ai_subtitle')}}:</p>
        <div class="PromptSteps mt-4">
          <div class="PromptStep mb-4 relative">
            <p class="PromptStep__Title font-bold text-xl">{{t('pages.course.course_generation.attach_file')}}</p>
            <p>{{t('pages.course.course_generation.attach_file_hint')}}</p>
            <div class="file-upload mt-2 mb-2">
              <input type="file" ref="fileInput" @change="handleFileUpload" multiple style="display: none;">
              <button class="flex items-center gap-2" @click="$refs.fileInput.click()" :disabled="files.length >= 3">
                <svg height="14" width="14" class="fill-current"> 
                  <use xlink:href="#icon-attachment"></use>
                </svg>
                <strong>{{ t('pages.comments.attach') }}</strong>
              </button>
              <p class="mt-2" v-if="files.length > 0">{{ files.length }} {{ t('pages.comments.files_attached') }}</p>
            </div>
            <div v-if="files.length > 0" class="file-preview ">
              <div v-for="(file, index) in files" :key="index" class="file-item mt-2">
                <span>{{ file.name || file.filename }}</span>
                <button class="Btn Btn--Icon Btn--Delete ml-2" @click="removeFile(index)">
                  <svg height="14" width="14" class="fill-current"> 
                    <use xlink:href="#icon-close"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        
        
          <!-- Step-by-step prompt system -->
          <div v-for="(prompt, index) in prompts" 
            :key="index" 
            :class="['PromptStep mb-4', 
              {'PromptStep--Confirmed': prompt.confirmed}, 
              {'PromptStep--NotConfirmed': !prompt.confirmed},
              {'PromptStep--InProgress': index === 0 || prompts[index - 1].confirmed}
            ]">
          <div class="">
            <div class="flex justify-between items-cente mb-2">
              <p class="PromptStep__Title font-bold text-xl">{{ t(`pages.course.course_generation.${prompt.title}`) }}</p>
              <span v-if="prompt.confirmed" class="Btn Btn--Icon Btn--Icon--Round Btn--Save ml-2" >
                  <svg height="14" width="14" class="fill-current"> 
                    <use xlink:href="#icon-checkmark"></use>
                  </svg>
                </span>
            </div>
            <label :for="`prompt-${index}`" class="PromptStep__Label mb-0">{{ t(`pages.course.course_generation.${prompt.label}`) }}</label>
            <p class="PromptStep__SubLabel text-grayScale-middle mb-2">{{ t(`pages.course.course_generation.${prompt.subLabel}`) }}</p>
              <div class="PromptStep__InputBox flex gap-4 items-end">
                <p v-if="prompt.confirmed && prompt.value">{{ prompt.value }}</p>
                <template v-else>
                  <div v-if="prompt.type === 'details_level'" class="w-full">

                    <label class="Radio">
                      <input type="radio" v-model="prompt.value" value="easy">
                      {{ t('pages.course.course_generation.not_detailed') }}
                    </label>
                    <label class="Radio">
                      <input type="radio" v-model="prompt.value" value="medium">
                      {{ t('pages.course.course_generation.medium_detailed') }}
                    </label>
                    <label class="Radio mb-0">
                      <input type="radio" v-model="prompt.value" value="advanced">
                      {{ t('pages.course.course_generation.very_detailed') }}
                    </label>

                  </div>
                  <textarea 
                    v-else
                    rows="2"
                    :id="`prompt-${index}`"
                    v-model="prompt.value" 
                    class="prompt-input"
                    @keyup.enter="confirmPrompt(index)"
                    :placeholder="prompt.placeholder"
                    :disabled="index > 0 && !prompts[index - 1].confirmed" >
                  </textarea>

                </template>
              <button 
                :class="['Btn Btn--Save ml-2', { 'Btn--Disabled': !prompt.value }]" 
                @click="confirmPrompt(index)"
              >
                {{ t('actions.save') }}
              </button>
            </div>
            <div class="text-right mt-2" v-if="prompt.confirmed">
              <button class="Btn Btn--Text text-warning" @click="prompt.confirmed = false">
               {{t('actions.edit')}}
              </button>
            </div>
          </div>
        </div>
        
        </div>
        <button class="Btn Btn--Save" @click="callAI" :disabled="!allPromptsCompleted">
          {{t('actions.generate')}}
        </button>
      </div>
    </div>
    
    <p v-else-if="courseContent && !loading" class="mt-6 mb-2 font-bold text-lg" @click="confirmSyllabus">{{ t('pages.quiz.ai_generation_ready') }}</p>
    
    <div v-if="loading" class="Box Box--Clear mt-4 mb-4">
      <div class="flex items-center justify-center image-placeholder animated-background mb-3">
        <p class="px-10 ai-loading text-lg font-bold">{{ t('pages.course.course_generation.ai_generation_loading') }}</p>
      </div>
    </div>
    
    <!--
    <div v-if="generatedSyllabus" class="mt-4">
      <vue-editor 
        :id="`prompt`"
        v-model="generatedSyllabus" 
        :editorToolbar="customToolbar"
        class="mb-4"
      >
      </vue-editor>
      <button class="Btn Btn--Save" @click="confirmSyllabus">Conferma</button>
      <button class="Btn Btn--Delete" @click="generatedSyllabus = ''">{{ t('actions.delete') }}</button>
    </div>
     -->

    <div v-if="courseContent" class="Form--Boxed mb-4">
      <div class="mb-6">
        <div class="field">
          <label for="subtitle">{{t('pages.course.subtitle')}}:</label>
          <textarea name="subtitle" id="subttle" cols="30" rows="2" v-model="courseContent.subtitle"></textarea>
        </div>
        <div class="field">
          <label for="description">{{t('pages.course.description')}}:</label>
          <vue-editor 
          :id="`description`"
          v-model="courseContent.description" 
          :editorToolbar="customToolbar"
          class="mb-4"
        />
        </div>
        <div>
          <p class="mb-2 font-bold">{{t('pages.course.syllabus')}}:</p>
          <ul class="">
            <li v-for="module in courseContent.syllabus" :key="module.title" class="">
              <p class="font-bold bg-grayScale-light p-2">{{ module.title }}</p>
              <ul class="List--Lined List--Lined--Thin">
                <li v-for="lesson in module.lessons" :key="lesson.title" class="lecture flex gap-2 items-start pb-2">
                  <span class="Tag">{{t('global.lectures.s')}}</span>
                  <div>
                    <span class="font-bold">{{ lesson.title }}</span>
                    <p class="text-sm">{{ lesson.description }}</p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <button class="Btn Btn--Save" @click="confirmSyllabus">{{t('actions.confirm')}}</button>
      <button class="Btn Btn--Delete" @click="deleteSyllabus">{{ t('actions.delete') }}</button>
    </div>

    <modal :name="`confirm-modal`" height="auto">
      <div class="Hero v--modal--body body--users p-4 pt-10 text-center">
        <img src="../../../images/party1.png" class="Hero__Img1"/>
        <img src="../../../images/party2.png" class="Hero__Img2"/>
        <p class="font-bold text-2xl mb-4">{{t('pages.confirm.congrats')}}</p>
        <p>{{t('pages.course.course_generation.content_generated')}}</p>
        <p class="mb-4">{{ t('pages.quiz.reload_page') }}</p>
        <button class="Btn Btn--Save" @click="reload">{{t('actions.reload')}}</button>
      </div>
    </modal>


  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import api from '../../../api'
import { VueEditor } from "vue2-editor";
import { global as EventBus } from '../../../js/utils/eventBus';

export default {
  props: {
    course: Object
  },
  components: {
    VueEditor
  },
  data() {
    return {
      courseContent: null,
      jobId: null,
      loading: false,
      customToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["code-block"],["link"], ["clean"]],
      files: [],
      deletedFileIds: [],
      error: null,
      prompts: [
        { title: 'topic', label: 'topic_label', subLabel: "topic_sub_label", value: '', placeholder: '', confirmed: false, type: 'topic' },
        { title: 'goals', label: 'goals_label', subLabel: "goals_sub_label", value: '', placeholder: '', confirmed: false, type: 'goals' },
        { title: 'audience', label: "audience_label", subLabel: "audience_sub_label", value: '', placeholder: '', confirmed: false, type: 'audience' },
        { title: 'details_level', label: "details_level_label", subLabel: "details_level_sub_label", value: '', placeholder: '', confirmed: false, type: 'details_level' },
      ]
    }
  },
  computed: {
    allPromptsCompleted() {
      return this.prompts.every(prompt => prompt.confirmed);
    }
  },
  methods: {
    handleFileUpload(event) {
      const newFiles = Array.from(event.target.files);
      const availableSlots = 3 - this.files.length;
      const filesToAdd = newFiles.slice(0, availableSlots);
      this.files = [...this.files, ...filesToAdd];
      
      if (newFiles.length > availableSlots) {
        this.$toastr.w(`Only ${availableSlots} file(s) added. Max limit reached.`);
      }
    },
    removeFile(index) {
      const removedFile = this.files[index];
      if (removedFile.id) {
        this.deletedFileIds.push(removedFile.id);
      }
      this.files.splice(index, 1);
    },
    confirmPrompt(index) {
      this.$set(this.prompts, index, { ...this.prompts[index], confirmed: true });
    },


    callAI(){
  
      this.loading = true

      //test wirth file upload
      const formData = new FormData();

      const prompts = {
        topic: this.prompts[0].value,
        goals: this.prompts[1].value,
        audience: this.prompts[2].value,
        details_level: this.prompts[3].value
      };

      formData.append('prompts', JSON.stringify(prompts));
      formData.append('course_id', this.course.id);
      this.files.forEach((file, index) => {
        formData.append(`attachments[]`, file);
      });

      console.log(this.prompts)
      console.log(formData)
      api.Course.createAiSyllabus(
        formData
      )
      .then(res => {
          console.log(res)
          //this.aiLoading = false
          //polly ajax
          this.jobId = res.job_id
          const intervalId = setInterval(() => {
            api.Course.checkAiCourseJobStatus({
              job_id: res.job_id
            })
            .then(resp => {
              if (resp.status == 'error') {
                console.log(resp)
                //this.aiError = resp.error_code
                this.$toastr.e('Error');
                this.loading = false;
                this.error = resp.error_message;
                clearInterval(intervalId); // Stop polling
              } else if (resp.text) {
                this.courseContent = this.parseJson(resp.text)
                console.log(this.courseContent)
                this.loading = false
                this.error = null
                clearInterval(intervalId); // Stop polling
              }
            })
            .catch(err => {
              this.$toastr.e(err);
              clearInterval(intervalId); // Stop polling if an error occurs
              this.error = err;
              this.loading = false;
            });
          }, 1500);
        } )
      .catch(e =>{
        this.loading = false
        this.$toastr.e(this.t('actions.error'));
      })
    },


    confirmSyllabus(){
      const target = event.currentTarget;
      EventBus.emit('loader:local:start', { elem: target })
      api.Course.confirmAiSyllabus({
        course_id: this.course.id,
        job_id: this.jobId,
      })
      .then( res => {
        this.$toastr.s(this.t('actions.saved'));
        EventBus.emit('loader:local:end', { elem: target })
        this.$modal.show(`confirm-modal`);
      }).catch( err => {
        EventBus.emit('loader:local:end', { elem: target })
        this.$toastr.e(this.t('actions.error'));
      } )
    },

    reload(){
      location.reload()
    },

    deleteSyllabus() {
      this.courseContent = null;
    },

    closeBox(){
      EventBus.emit('close:ai-syllabus-generator')
    },

    parseJson(content){

      //sometimes this.courseContent may be "Hre is the generatred content: {subtitle: "..", ....}"
      //so we need to extract the json part and remove the rest
      const jsonStart = content.indexOf('{')
      const jsonEnd = content.lastIndexOf('}')
      const json = content.substring(jsonStart, jsonEnd + 1)
      return JSON.parse(json)
      
    }

  }
}
</script>

<style scoped>


</style>
