<template>
  
  <div class="Player" ref="player">
    <div class="Player__Container">
      <div :id="`handstick-${keyId}`"></div>
    </div>

    <modal name="skipped-modal">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-10">
        <div class="LectureEnd" >
          <p class="text-lg mb-1">Per andare alla lezione successiva, occorre vedere tutto il video.</p>
          <p class="text-lg mb-2" v-if="secondsSkipped">Guarda il video per intero, ti mancano {{ secondsSkipped }}.</p>
          <button class="Btn Btn--Save" @click="goBack()">Riprendi</button>
        </div>
      </div>
    </modal>

    <modal name="end-lecture-modal" @opened="startCount" @closed="stopCount">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-10">
        <div class="LectureEnd" >
          <p class="text-xl mb-4">{{ t('pages.lecture.lecture_completed')}}: <b>{{lecture.name}}</b></p>
          <p class="text-2xl mb-4" v-if="course.show_percentage">
            {{ t('pages.lecture.percentage_completed')}} 
            <span><strong class="text-3xl">{{Math.floor(resp.percentageCompleted)}}%</strong></span> 
            {{ t('pages.lecture.of_the_course')}}!
          </p>
          <p v-if="course.points && prizesActive && resp.lastCompleted && resp.completedCourse" class="text-lg mb-4">
            {{ t('pages.lecture.congratulations_points', { course_points: course.points }) }}
          </p>
          <a  v-if="resp.lastCompleted && resp.completedCourse && course.certification" 
              class="Btn Btn--Text mb-2"
              :href="`/certifications/${resp.completedCourse.unique_identifier}`"
              target="_black">{{ t('pages.lecture.download_certification')}}
               <svg height="16" width="16" class="fill-current ml-2"> 
                <use xlink:href="#icon-download"></use>
              </svg>
            </a>
          <p v-if="resp.badgeObtained" class="text-lg mb-4 mt-4">
            <strong>Complimenti!</strong> Hai ottenuto il badge: <strong>{{ resp.badgeObtained}}</strong>
          </p>
          <a  v-if="resp.badgeObtained" 
            class="Btn Btn--Primary Btn--Arrow mr-2 ml-2 mb-4"
            :href="`/users/badges`"
            target="_black">{{ t('pages.home.your_badge') }}
          </a>
          <div v-if="next" id="countdown">
            <span id="countdown-number">{{countDown}}</span>
            <svg>
              <circle r="18" cx="20" cy="20"></circle>
            </svg>
          </div>
          
          <div class="flex justify-center">
            <a  :href="lecture.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.restart')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-reload"></use>
              </svg>
            </a>
            <a v-if="next" :href="next.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.next_lecture')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
            <a v-if="nextCourse && !next" :href="nextCourse.id" class="Btn Btn--Primary Btn--Arrow mr-2 ml-2" @click="stopCount" >
              {{ t('pages.lecture.next_course')}} 
              <svg height="16" width="16" class="fill-current"> 
                <use xlink:href="#icon-arrow-right"></use>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </modal>

    <modal name="quiz-modal" class="v--modal--prevent-close">
      <div class="Modal__Body flex items-center justify-center h-full text-center p-5">
        <quiz :lectureQuiz="quiz" :course="course" :next="next" />
      </div>
    </modal>

  </div>

</template>

<script>
import Player from '@vimeo/player';
import api from '../../api'
import { global as EventBus } from '../../js/utils/eventBus'; 
import quiz from '../quiz/show'
import { mapState, mapActions, mapGetters } from 'vuex'
import lecture from '../../api/lecture';


export default {
  name: 'Vimeo',
  components: { 
    quiz
  },

  props: {
    videoId: String,
    lecture: Object,
    course: Object,
    blockForward: Boolean,
    next: String,
    keyId: String,
    multipleVideos: Boolean,
    videoIndex: Number,
    quiz: Object,
    videoProgress: Number,
    autoplay: Boolean,
    nextCourse: String,
    isTaxLegal: Boolean,
    prizesActive: Boolean,
  },

  data() {
    return {
      paused: true,
      resp: Object,
      countDown: 10,
      muted: false,
      previous: 0,
      videoProgresRealTime: this.videoProgress,
      secondsSkipped: '',
      lastSkippedPoint: 0,
      player: null
    }
  },


  computed: {
    ...mapGetters({
      quizResult: "quiz",
    }),

    contentId() {
      return this.lecture.contents.find((content) => {
        return content.content_type === 'vimeo'
      }).id
    }
  },


  mounted() {
    console.log("Prizes Active:", this.prizesActive);
    console.log("Course Points:", this.course.points);
    console.log("Next lecture:")
    console.log(this.next);
    console.log("Next Course:");
    console.log(this.nextCourse);

    const player = new Player(`handstick-${this.keyId}`, {
        id: this.videoId,
        responsive: true,
        autoplay: this.autoplay,
        speed: !this.blockForward,
        quality: '720p',
        dnt: true,
      });
    
    this.player = player

    
    EventBus.emit('loader:local:start', {
      elem: this.$refs.player.parentElement
    })

    EventBus.on('video:play', el => {
      player.play().then(function() {
        console.log('Video played')
      }).catch(function(error) {
        console.warn(error)
      })
    }),
    
    player.on('loaded', async (data) => {
      console.log('Video Loaded...')

      let duration = await player.getDuration();
      // If this.videoProgress exists, and duration is more than 10 seconds bigger than videoProgress
      if (this.videoProgress && (duration - this.videoProgress > 10)) {
        player.setCurrentTime(this.videoProgress)
      }
      //get current time
      const currentTime = await player.getCurrentTime()
      this.previous = currentTime

      EventBus.emit('loader:local:end', {
        elem: this.$refs.player.parentElement
      })
      if (this.autoplay)  {
        player.play().then(function() {
          console.log('Video played')
        }).catch(function(error) {
          console.warn(error)
        })
      }
    })

    player.on('pause', (data) => {
      console.log(`Pause: ${data.seconds }`)
    })

    let timeWatched = this.videoProgress || 0;
    let count = 0;

    player.on("timeupdate", (data) => {

      if (data.seconds - 1 < timeWatched && data.seconds > timeWatched) {
        timeWatched = data.seconds;
        //console.log('Timeupdate triggered')
      } else if (data.seconds > timeWatched) { // This condition now specifically checks for fast forwarding
        if (this.blockForward) {
          console.log('Must block forward')
          player.pause()
          player.setCurrentTime(timeWatched); // Reset to the last known timeWatched to prevent forward seeking
          player.play()
        }
      }
      

      let delta = this.lecture.duration && this.lecture.duration < 600 ? 19 : 38
      count = count + 1
      if (count % delta == 0) {
        let interval = data.seconds - this.previous
        if (interval > 0 && data.seconds <= timeWatched + 1 ) { 
          console.log(`Timeupdate: data.seconds: ${data.seconds}, previous: ${this.previous}, interval: ${interval},`)
          console.log(`Updating duration and resuming video`)
          this.updateDurationAndResume(interval, player)
          this.previous = data.seconds
        } 
      }

    });

    player.on("seeked", (data) => {
      console.log(`Seeked at: ${data.seconds}`)
      this.previous = data.seconds
      if (this.blockForward && timeWatched < data.seconds ) {
        player.setCurrentTime(timeWatched);
      }
    });

    player.on('ended', (data) => {
      let interval = data.seconds - this.previous
      console.log(`Interval: ${interval}, Previous: ${this.previous}`)
      //this.updateDurationAndResume(interval, player)
      if (this.blockForward) {
        this.checkSkippedVideo()
      } else {
        this.handleEndLecture()
      }
    });

  },

  watch: {
    quizResult: {
      handler(newVal, oldVal) {
        console.log(`Quiz passed: ${newVal.passed}. Old val: ${oldVal.passed}`)
        if (newVal.passed) {
          api.CompletedLecture.create(this.lecture.id)
            .then((res) => {
              this.resp = res
              console.log(res)
          })
        }
      },
      deep: true  
    }
  },

  methods: {

    goBack() {
      this.$modal.hide('skipped-modal')
      const lastSkippedPoint = this.lastSkippedPoint || 0
      this.player.setCurrentTime(lastSkippedPoint).then(() => {
        this.player.play()
      }).catch(function(error) {
        console.warn(error)
      })
    },

    checkSkippedVideo() {
      api.UserActivity.checkIfSkipped(this.lecture.id)
        .then((resp) => {
          if (resp.skipped) {
            console.log('Skipped video')
            api.Lecture.updateVideoProgress(this.contentId, this.videoProgresRealTime, this.blockForward)
              .then((res) => {
                this.secondsSkipped = resp.seconds_skipped
                this.lastSkippedPoint = resp.point_to_restart
                this.$modal.show('skipped-modal', {
                      width: '100%',
                      maxWidth: '600px',
                      adaptive: true
                });
              })
          } else {
            this.handleEndLecture()
          }
        })
    },


   async updateDurationAndResume(interval, player) {
     try {
      await api.UserActivity.updateDuration(this.lecture.id, interval)
      const currentTime = await player.getCurrentTime()
      if (this.blockForward && currentTime < this.videoProgresRealTime) {
        console.log('You are reviewig, do not update video progress')
        return
      }
      const res = await api.Lecture.updateVideoProgress(this.contentId, currentTime, this.blockForward)
      if (res.status === 'warning-seek-forward') {
        console.warn('Trying to seek forward, you fool')
        player.pause()
        player.setCurrentTime(res.last_point_saved)
      } else {
        console.log(`Resuming video, ${currentTime}`)
        this.videoProgresRealTime = currentTime
      }
      
     } catch (error) {
      console.warn(error)
      location.reload()
     }

    },

    handleEndLecture() {
      if (!this.quiz || (this.quiz && this.quiz.questions.length === 0) ) {
        api.CompletedLecture.create(this.lecture.id)
          .then((res) => {
            this.resp = {
              ...res,
              badgeObtained: res.badgeObtained
            };
            this.$modal.show('end-lecture-modal', {
              width: '100%',
              maxWidth: '600px',
              adaptive: true
            });
          })
      } else {
        this.$modal.show('quiz-modal', {
              width: '100%',
              maxWidth: '600px',
              adaptive: true
        });
      }
    },

    startCount() {
      if (this.countDown > 0 && this.next) {
        this.timeout = setTimeout(() => {
            this.countDown -= 1;
            this.startCount()
        }, 1000)
      } else {
        if (this.next) {
          window.location.href = this.next.id
        }
          
      }
    },

    stopCount() {
      clearTimeout(this.timeout)
      this.countDown = 10;
    },

    nextVideo (event) {
      this.$emit('nextVideo', 'someValue')
    }

  }
}
</script>


<style scoped lang="scss">

#countdown {
  position: relative;
  margin: auto;
  margin-bottom: 24px;
  height: 40px;
  width: 40px;
  text-align: center;
}


#countdown-number {
  display: inline-block;
  line-height: 40px;
  color: var(--color-primary);
}


#countdown svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: theme('colors.primary');
  fill: transparent;
  animation: countdown 10s linear infinite forwards;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 113px;
  }
}

.Player {
  position: relative;
  &__Container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
  &__Controls {
    transition: all .3s;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    padding: 8px;

    @media (min-width: 48em) {
      opacity: 0;
      visibility: hidden;
    }

    button {
      display: inline-flex;
      align-items: center;
      padding: 4px;
      background: rgba(255, 255, 255, .2);
      border-radius: 7px;
      font-size: 13px;
      transition: background .2s;
      &:hover {
        background: rgba(255, 255, 255, .4);
      }
    }
  }

  &:hover {
      .Player__Controls {
        opacity: 1;
        visibility: visible;
      }
    }
}
</style>